import $ from 'jquery';

export const dispatchNotificationBarChangeEvent = () =>
  window.dispatchEvent(new CustomEvent('notification-bar-change'));

export const notificationBarStorageKey = () => {
  const notificationBar = document.getElementById('notification-bar-close');
  return notificationBar ? notificationBar.dataset.storageKey : false;
};

export const checkIfReferralNotification = () => {
  const storageKey = notificationBarStorageKey();
  return (
    storageKey === window.NOTIFICATION_BAR_STORAGE_KEYS.REFEREE ||
    storageKey === window.NOTIFICATION_BAR_STORAGE_KEYS.PARTNER_REFEREE ||
    storageKey === window.NOTIFICATION_BAR_STORAGE_KEYS.ARDIUS_REFEREE
  );
};

export const showNotificationBar = () => {
  $('#notification-bar').removeClass('hide');
  if (checkIfReferralNotification()) {
    fetch('/referral_notification');
  }
};
