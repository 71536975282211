export function localStorageGetItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    return null;
  }
}

export function localStorageSetItem(key, val) {
  try {
    return localStorage.setItem(key, val);
  } catch (err) {
    return undefined;
  }
}

export function localStorageRemoveItem(key) {
  try {
    return localStorage.removeItem(key);
  } catch (err) {
    return undefined;
  }
}

export function sessionStorageGetItem(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (err) {
    return null;
  }
}

export function sessionStorageSetItem(key, val) {
  try {
    return sessionStorage.setItem(key, val);
  } catch (err) {
    return undefined;
  }
}
