import $ from 'jquery';
import { sessionStorageGetItem, sessionStorageSetItem } from './storage_helpers';
import {
  checkIfReferralNotification,
  dispatchNotificationBarChangeEvent,
} from './notification_bar_helper';

export const anyReferralNotificationDismissed = () =>
  sessionStorageGetItem('anyReferralNotificationDismissed');

export const anyReferralModalDismissed = () =>
  sessionStorageGetItem('referralModalDismissed') ||
  sessionStorageGetItem('ardiusReferralModalDismissed') ||
  sessionStorageGetItem('partnerReferralModalDismissed');

export const hideNotificationBar = () => {
  $('#notification-bar').addClass('hide');
  $('.navbar-underside').removeClass('notification');
  dispatchNotificationBarChangeEvent();
};

export const handleNotificationBarClose = () => {
  hideNotificationBar();
  fetch('/dismiss_notification');
  if (checkIfReferralNotification()) {
    sessionStorageSetItem('anyReferralNotificationDismissed', 'true');
  }
};

const dispatchCountdownBannerChangeEvent = () =>
  window.dispatchEvent(new CustomEvent('notification-bar-change'));

$(document).on('click', '#notification-bar-close', handleNotificationBarClose);

// eslint-disable-next-line func-names
$(document).on('keypress', '#notification-bar-close', function (e) {
  if (e.which === 13 || e.which === 32) {
    e.preventDefault();
    handleNotificationBarClose();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  dispatchCountdownBannerChangeEvent();

  // Handles back and forward loading pages with referral notifications
  if (anyReferralModalDismissed()) {
    if (anyReferralNotificationDismissed()) {
      $('#notification-bar').addClass('hide');
    } else {
      $('#notification-bar').removeClass('hide');
    }
    dispatchNotificationBarChangeEvent();
  }
});
